<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos - Unidades de Medidas</h4>
            <div class="small text-muted">Administración de unidades de medidas</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Unidad de Medida">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="8" id="view_list_unit">
          <b-card>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                      
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>       

                <template v-slot:cell(id)="data">
                  <b>{{data.item.id}}</b>
                </template>

                <template v-slot:cell(reference)="data">
                  <b>{{data.item.reference}}</b>
                </template>

                <template v-slot:cell(name)="data">
                  {{data.item.name}}
                </template>

                <template v-slot:cell(equivalence_fixed)="data">
                  <b-badge variant="info" v-if="getEquivalenceFixed(data.item.equivalences)">
                    {{getEquivalenceFixed(data.item.equivalences)}} Equivalencia/s
                  </b-badge>
                  <b-badge variant="warning" v-else>Sin Equivalencia</b-badge>
                </template>
                
                <template v-slot:cell(equivalence_var)="data">
                  <b-badge variant="info" v-if="getEquivalenceVar(data.item.equivalences)">
                    {{getEquivalenceVar(data.item.equivalences)}} Equivalencia/s
                  </b-badge>
                  <b-badge variant="warning" v-else>Sin Equivalencia</b-badge>
                </template>                
                
                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>            
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-card>
        </b-col>

        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- ITEM SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-unit-title" v-if="itemSelected">
                        <div class="crud-unit-title">
                          <span class="crud-unit-code" v-if="itemSelected.name">                          
                            {{itemSelected.name}}
                          </span>                         
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row v-if="itemSelected">
                    <b-col md="12">                                                  
                      <b-table  class="mb-0 table-unit-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         
                          
                        <template v-slot:cell(formula)="row">                      
                          <div v-html="getFormulaEquivalences(row.item)" :id="'popover-equivalence-' + row.item.id" style="cursor:pointer;"></div>   
                          
                          <b-popover :target="'popover-equivalence-' + row.item.id" variant="info" triggers="hover">                            
                            <span v-if="parseFloat(row.item.surcharge)>0">RECARGO: {{ parseFloat(row.item.surcharge) }}%</span>                        
                            <span v-else><i>SIN RECARGO</i></span>
                          </b-popover>    
                        </template>                      

                        <template v-slot:cell(result)="row">                        
                          {{row.item.unit_measurement_result.reference}}
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>                    
                  </b-row>
                  <b-row v-else>
                    <b-col md="12">    
                      <b-alert variant="info" show>Seleccione una unidad de medida</b-alert>               
                    </b-col>
                  </b-row>                  
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>          
      </b-row>        
      
      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_unit">  
          <b-card header-tag="header" footer-tag="footer">
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="3">
            <b-form-group label="Referencia">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.reference"
                            required
                            placeholder="Ingresar una referencia">
              </b-form-input>
            </b-form-group>
          </b-col>                 
          <b-col md="9">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre">
              </b-form-input>
            </b-form-group>
          </b-col>         
        </b-row>

        <b-row v-if="crud.form.equivalence.unit_measurement_entry">
          <b-col md="12">
            <hr>
          </b-col>

          <b-col md="12">
            <b-row :key="keyForceUpdate">
              <b-col md="2" >
                <b-form-group label="Entrada">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.equivalence.unit_measurement_entry.name"
                                :readonly="true">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <FindObject type="unitMeasurement" 
                            tag="Salida"
                            @select-object="loadUnitMeasurementResult($event)" 
                            :valueID="crud.form.equivalence.unit_measurement_result_id"
                            render="search"/>                
              </b-col>              
              <b-col md="3">
                <b-form-group label="Operación">                  
                  <b-form-select v-model="crud.form.equivalence.operation" :options="arr.select.operation" size="sm"></b-form-select>                    
                </b-form-group>                                
              </b-col>              
              <b-col md="2" v-if="crud.form.equivalence.operation=='division_fijo' || crud.form.equivalence.operation=='multiplicacion_fijo'">
                <b-form-group label="Valor">
                  <b-form-input type="number"
                                size="sm"
                                v-model="crud.form.equivalence.value"
                                placeholder="Ingrese un valor numérico">
                  </b-form-input>              
                </b-form-group>                
              </b-col>
              <b-col md="2" v-if="crud.form.equivalence.operation=='division_variable' || crud.form.equivalence.operation=='multiplicacion_variable'">
                <b-form-group label="Variable">
                  <b-form-select v-model="crud.form.equivalence.variable" :options="arr.select.typeVariable" size="sm"></b-form-select>                    
                </b-form-group>                
              </b-col>              

              <b-col md="1">
                <b-button variant="success" @click="addEquivalence()" size="sm" title="Agregar Equivalencia" class="crud-unit-measuremente-equivalence-add">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                </b-button> 
              </b-col>
            </b-row>
            <b-row>
              
              <b-col>              
                <b-row>
                  <b-col md="12">                                                  
                    <b-table  class="mb-0 table-unit-sub"
                              responsive
                              head-variant="dark"
                              :hover="true"
                              :small="true"
                              :fixed="true"
                              :items="tableEquivalenece.items"
                              :fields="tableEquivalenece.fields"                            
                              v-if="tableEquivalenece.items.length">   
                      
                      <template v-slot:table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"                    
                          :key="field.key"
                          :style="{ width: field.width }"
                        >
                      </template>                         
                      
                      <template v-slot:cell(formula)="row">   
                        <div v-html="getFormulaEquivalences(row.item)"></div>  
                      </template>                      

                      <template v-slot:cell(result)="row">                        
                        {{row.item.unit_measurement_result.reference}}
                      </template>

                      <template v-slot:cell(surcharge)="row">                        
                        <span v-if="parseFloat(row.item.surcharge)>0">{{ parseFloat(row.item.surcharge) }}%</span>                        
                        <span v-else><i>SIN RECARGO</i></span>
                      </template>                      

                      <template v-slot:cell(f_action)="data">
                        <b-dropdown right 
                                    text="Acción" 
                                    size="sm" 
                                    variant="outline-dark" 
                                    class="pull-right" >
                                                                            
                          <b-dropdown-header>Acciones</b-dropdown-header>                                                    
                          <b-dropdown-item 
                            @click="openSurcharge(data.item)" 
                            title="Aplica un recargo al cálculo del precio"
                            v-if="data.item.id">
                            <b-icon icon="percent"></b-icon> Recargo
                          </b-dropdown-item>                          
                          <b-dropdown-item @click="removeEquivalence(data.index)">
                            <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-table>                      
                    <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                  </b-col>                    
                </b-row> 
              </b-col>              

            </b-row>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD SURCHARGE -->
      <b-modal v-model="modal.surcharge.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.surcharge.title}}
        </div>

        <b-row>
          <b-col md="2">
            <b-form-group label="ID">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.surcharge.id"
                            :disabled="true">
              </b-form-input>
            </b-form-group>
          </b-col>                 
          <b-col md="7">
            <b-form-group label="Referencia">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.surcharge.reference"
                            :disabled="true">
              </b-form-input>
            </b-form-group>
          </b-col>    
          <b-col md="3">
            <b-form-group label="Recargo">
              <b-input-group size="sm" append="%">
                <b-form-input type="number"
                                size="sm"
                                v-model="crud.surcharge.surcharge"
                                required>
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>                   
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.surcharge.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveSurcharge()">Guardar</b-button>          
        </div>
      </b-modal>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'

  export default {
    components: {
      FindObject,
    },     
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudUnitMeasurement',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [],
          currentPage: 1,
          perPage: 50,
        },
        tableSub : {
          items: [],
          fields: []
        },       
        tableEquivalenece : {
          items: [],
          fields: []
        },                 
        crud: {
          form: {
            id: 0,
            reference: '',
            name: '',            
            equivalence: {
              operation: 'division_fijo',
              variable: 'cantidad',
              value: 0,
              unit_measurement_entry: null,
              unit_measurement_entry_id: 0,              
              unit_measurement_result: null,
              unit_measurement_result_id: 0,
              detail: [],
            }
          }, 
          surcharge: {
            id: 0,
            reference: '',
            surcharge: 0,
          }       
        },
        modal: {
          form: {
            active: false,
            title: ''
          },  
          surcharge: {
            active: false,
            title: ''
          }        
        },    
        arr: {
          units: [],
          select: {
            operation: [
              { value: 'division_fijo', text: 'DIVISION  (FIJO)' },
              { value: 'division_variable', text: 'DIVISION (VARIABLE)' },
              { value: 'multiplicacion_fijo', text: 'MULTIPLICACION (FIJO)' },
              { value: 'multiplicacion_variable', text: 'MULTIPLICACION (VARIABLE)' },
            ],
            typeVariable: [              
              { value: 'cantidad', text: 'CANTIDAD' },
              { value: 'densidad', text: 'DENSIDAD' },
            ]            
          }
        },
        itemSelected: null,    
        keyForceUpdate: 0,
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {            
      this.load()
      this.loadStyleConfig()
      this.loadFieldTable()
    },
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_unit = document.getElementById('view_list_unit')
        var view_footer_unit = document.getElementById('view_footer_unit')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
              
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_unit.classList.remove('pr-0')
          view_footer_unit.classList.remove('pr-0')
        } else {          
          view_list_unit.classList.add('pr-0')
          view_footer_unit.classList.add('pr-0')
        }        
      },   
      loadFieldTable () {
        // TABLE UNIDADES DE MEDIDAS
        this.table.fields.push({key: 'id', label: 'Nº', sortable: true, class:"align-middle text-center", width:"5%"})
        this.table.fields.push({key: 'reference', label: 'Referencia', class:"align-middle text-center", width:"15%"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle text-left", width:"30%"})
        this.table.fields.push({key: 'equivalence_fixed', label: 'Equivalencia Fijas', class:"align-middle text-center", width:"20%"})
        this.table.fields.push({key: 'equivalence_var', label: 'Equivalencia Variable', class:"align-middle text-center", width:"20%"})
        this.table.fields.push({key: 'f_action', label: '', class:"align-middle text-right", width:"10%"})                      

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'formula', label: 'Formula', class:"align-middle", width:"70%"})                          
        this.tableSub.fields.push({key: 'result', label: 'Resultado', class:"align-middle", width:"30%"})                          

        // TABLE EQUIVALENCIAS        
        this.tableEquivalenece.fields.push({key: 'formula', label: 'Formula', class:"align-middle", width:"45%"})                
        this.tableEquivalenece.fields.push({key: 'result', label: 'Resultado', class:"align-middle", width:"25%"})                         
        this.tableEquivalenece.fields.push({key: 'surcharge', label: 'Recargo', class:"align-middle", width:"20%"}) 
        this.tableEquivalenece.fields.push({key: 'f_action', label: '', class:"align-middle", width:"10%"})                 
      },         
      getRowCount (items) {
        return items.length
      },      
      onRowSelected(item) {         
        this.arr.units.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
          
      // CRUD
      load(forceEquivalence=false) {
        var result = serviceAPI.obtenerUnidadesMedidas()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.units = data

          this.table.items.forEach(element => {                        
            if(forceEquivalence) {
              if(element.id == this.itemSelected.id) {
                this.edit(element)
              }              
            }    
          })

        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.reference = '' 
        this.crud.form.name = '' 

        this.crud.form.equivalence.operation = 'division_fijo'
        this.crud.form.equivalence.variable = 'cantidad'
        this.crud.form.equivalence.value = 0
        this.crud.form.equivalence.unit_measurement_entry = null
        this.crud.form.equivalence.unit_measurement_entry_id = 0              
        this.crud.form.equivalence.unit_measurement_result = null
        this.crud.form.equivalence.unit_measurement_result_id = 0
        this.crud.form.equivalence.detail = []
        
        this.modal.form.title = "Nueva Unidad de Medida"
        this.modal.form.active = true
      },
      edit(item) {
        this.openSub(item)

        this.crud.form.id = item.id
        this.crud.form.reference = item.reference
        this.crud.form.name = item.name                 

        this.crud.form.equivalence.operation = 'division_fijo'
        this.crud.form.equivalence.variable = 'cantidad'
        this.crud.form.equivalence.value = 0
        this.crud.form.equivalence.unit_measurement_entry = item,
        this.crud.form.equivalence.unit_measurement_entry_id =  item.id,              
        this.crud.form.equivalence.unit_measurement_result = null
        this.crud.form.equivalence.unit_measurement_result_id = 0
        this.crud.form.equivalence.detail = []

        this.loadEquivalences(item)

        this.modal.form.title = "Editar Unidad de Medida"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Unidad de Medida',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarUnidadMedida(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarUnidadMedida(this.crud.form);
        } else {
          var result = serviceAPI.agregarUnidadMedida(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      
      // EQUIVALENCIA
      loadUnitMeasurementResult(object) {
        if(object){
          this.crud.form.equivalence.unit_measurement_result = object
          this.crud.form.equivalence.unit_measurement_result_id = object.id
        } else {
          this.crud.form.equivalence.unit_measurement_result = null
          this.crud.form.equivalence.unit_measurement_result_id = 0
        }
      },
      loadEquivalences(item) {
        if(item.equivalences) {
          item.equivalences.forEach(element => {            
            var variable = ''
            var value = 0

            if(element.operation == 'division_variable' ||
               element.operation == 'multiplicacion_variable') {             
              variable = element.variable
              value = 0
            } 

            if(element.operation == 'division_fijo' ||
               element.operation == 'multiplicacion_fijo') {                     
              variable = ''
              value = element.value        
            }         

            this.crud.form.equivalence.detail.push({
              id: element.id,
              unit_measurement_entry: this.crud.form.equivalence.unit_measurement_entry,
              unit_measurement_entry_id: this.crud.form.equivalence.unit_measurement_entry_id,
              operation: element.operation,
              variable: variable,
              value: value,
              unit_measurement_result: element.unit_measurement_result,
              unit_measurement_result_id: element.unit_measurement_result_id,     
              surcharge: element.surcharge,              
            })
          });
        }       
        
        this.tableEquivalenece.items = this.crud.form.equivalence.detail 
        this.clearEquivalence()
      },
      addEquivalence() {

        // NO SE PUEDE GUARDAR MAS DE UNA VEZ LA MISMA EQUIVALENCIA
        var existe = false
        if(this.crud.form.equivalence.detail.length) {
          this.crud.form.equivalence.detail.forEach(element => {
            if(element.unit_measurement_result_id == this.crud.form.equivalence.unit_measurement_result_id) {              
              existe = true
            }
          });
        }
        if(existe) {
          this.$awn.alert("La equivalencia ya se encuentra agregada");
          return false
        }        

        // NO SE PUEDE GUARDAR UNA EQUIVALENCIA CON LAS MISMAS UNIDADES DE MEDIDAS
        if(this.crud.form.equivalence.unit_measurement_entry_id == this.crud.form.equivalence.unit_measurement_result_id) {              
          this.$awn.alert("La entrada y salida deben ser diferentes");
          return false          
        }        

        // LA UNIDAD DE MEDIDA DE RESULTADO DEBE ESTAR CARGADA
        if(!this.crud.form.equivalence.unit_measurement_result_id) {              
          this.$awn.alert("La unidad de medida de resultado debe estar cargada");
          return false          
        }                

        // AGREGO REGISTRO
        var variable = ''
        var value = 0

        if(this.crud.form.equivalence.operation == 'division_variable' ||
           this.crud.form.equivalence.operation == 'multiplicacion_variable') {             
          variable = this.crud.form.equivalence.variable
          value = 0

          // VALIDO QUE LA VARIABLE ESTE CARGAGA
          if(!variable) {
            this.$awn.alert("No se cargo la variable para la formula");
            return false                      
          }          
        } 

        if(this.crud.form.equivalence.operation == 'division_fijo' ||
           this.crud.form.equivalence.operation == 'multiplicacion_fijo') {                 
          variable = ''
          value = this.crud.form.equivalence.value        

          // VALIDO QUE EL VALOR ESTE CARGAGO
          if(!value) {
            this.$awn.alert("No se cargo el valor para la formula");
            return false                      
          }
        }

        this.crud.form.equivalence.detail.push({
            id: 0,
            unit_measurement_entry: this.crud.form.equivalence.unit_measurement_entry,
            unit_measurement_entry_id: this.crud.form.equivalence.unit_measurement_entry_id,
            operation: this.crud.form.equivalence.operation,
            variable: variable,
            value: value,
            unit_measurement_result: this.crud.form.equivalence.unit_measurement_result,
            unit_measurement_result_id: this.crud.form.equivalence.unit_measurement_result_id,          
        })

        this.tableEquivalenece.items = this.crud.form.equivalence.detail 
        this.clearEquivalence()
      },
      removeEquivalence(index) {
        this.crud.form.equivalence.detail.splice(index, 1)
      },
      clearEquivalence() {
        this.crud.form.equivalence.operation = 'division_fijo'
        this.crud.form.equivalence.variable = 'cantidad'
        this.crud.form.equivalence.value = 0        
        this.crud.form.equivalence.unit_measurement_result = null
        this.crud.form.equivalence.unit_measurement_result_id = 0
        this.forceUpdate()  
      },
      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },
      getEquivalenceFixed(item) {
        var cant = 0
        if(item) {          
          item.forEach(element => {
            if(element.operation == 'division_fijo' || element.operation == 'multiplicacion_fijo') {
              cant = cant + 1
            }
          });
        }        
        return cant
      },
      getEquivalenceVar(item) {
        var cant = 0
        if(item) {
          item.forEach(element => {
            if(element.operation == 'division_variable' || element.operation == 'multiplicacion_variable') {
              cant = cant + 1
            }
          });
        }
        return cant
      },  
      getFormulaEquivalences(item) {        
        let unit_measurement = null
        if(item.unit_measurement_input != undefined) {
          unit_measurement = item.unit_measurement_input
        }
        if(item.unit_measurement_entry != undefined) {
          unit_measurement = item.unit_measurement_entry
        }

        let reference = ''
        if(item.operation=='division_fijo') {
          reference = unit_measurement.reference + ' / ' + parseFloat(item.value).toFixed(4) + ' = ' + item.unit_measurement_result.reference
        }
        if(item.operation=='multiplicacion_fijo') {
          reference = unit_measurement.reference + ' x ' + parseFloat(item.value).toFixed(4) + ' = ' + item.unit_measurement_result.reference
        }
        if(item.operation=='division_variable') {
          reference = unit_measurement.reference + ' / ' + item.variable.toUpperCase() + ' = ' + item.unit_measurement_result.reference
        }
        if(item.operation=='multiplicacion_variable') {
          reference = unit_measurement.reference + ' x ' + item.variable.toUpperCase() + ' = ' + item.unit_measurement_result.reference
        }  
        return reference
      },
      
      // RECARGO
      openSurcharge(item) {        
        this.crud.surcharge.id = item.id
        this.crud.surcharge.reference = this.getFormulaEquivalences(item)
        this.crud.surcharge.surcharge = item.surcharge

        this.modal.surcharge.title = "Agregar Recargo"
        this.modal.surcharge.active = true
      },
      saveSurcharge() {                
        if (!this.crud.surcharge.id) {
          this.$awn.alert("No se encuentra la equivalencia");
          return false;
        } 

        let loader = this.$loading.show();

        var result = serviceAPI.aplicarRecargo(this.crud.surcharge);
        result.then((response) => {
          this.modal.surcharge.active = false
          loader.hide()          
          this.$awn.success("Datos guardados con éxito");

          this.load(true)
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // COMPROBANTE DETAIL
      openSub(item) {                
        this.itemSelected = item        
        
        this.tableSub.items = item.equivalences
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      }, 
    }    
  }
</script>
<style>
  .crud-sales-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-sales-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }       
  .table-sales-sub {
    overflow: auto;
    max-height: 350px;
  }  
  .crud-unit-measuremente-equivalence-add {
    margin-top: 30px;
  }
  .table-unit-sub {
    min-height: 100px;
  }
</style>